import { MDBLazyLoading } from 'mdb-react-ui-kit';
import { Suspense, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

export function BigPoint({x, y, text, size, id, imageScale}) {

  let { height, width } = size;
  let {wscale, hscale} = imageScale;

  let dotSize = width * 0.75 * 0.001 * 17.5;
  dotSize = Math.min(dotSize, 10);
  
  x = Number(x);
  y = Number(y);

  //coordinate compatability (0, 0 being pacific ocean approximate)
  x += 132.475894; //latitude
  y -= 54.314174; //longitude

  x *= 15.212513;
  y *= -19.65025;

  x *= wscale;
  y *= hscale;

  x += dotSize/2
  y += dotSize/2

  x = Math.min(Math.max(0, x), 900);
  y = Math.min(Math.max(0, y), 525);

  return (
    <div className="map-dot" style={{ top: y + "px", left: x + "px", width: dotSize + "px", height: dotSize + "px" }}>
      <img alt="Map" className="undraggable-image" style={{ width: dotSize + "px", height: dotSize + "px" }} src="/images/map/MapDot.png"/>
      <a href={"/Shootings?id="+id} target="_blank"><p style={{ overflow: "visible", color: "#FABEAB", fontSize: 10 }}><b>{text.split(",")[0]}</b></p></a>
    </div>
  );
}

export function BigMap({Places, ScreenSize}) {

  const map = useRef(null);

  const [imageScale, setImageScale] = useState({
    wscale: 1,
    hscale: 1
  });

  useEffect(() => {

    if (imageScale.wscale === map.current.clientWidth / 1116 && imageScale.hscale === map.current.clientHeight / 693) {
      return;
    }

    setImageScale(
      {
        wscale: map.current.clientWidth / 1116,
        hscale: map.current.clientHeight / 693
      }
    );
  });

  return (
    <div className="big-map-container">
      <center style={{maxWidth: "1116px", maxHeight: "693px"}}>
        <Suspense>
          <img ref={map} src="/images/map/Map.svg" className="undraggable-image big-map-image" alt="Map"/>
        </Suspense>
      </center>
      {Places.map(
        value => <BigPoint 
        key={value["id"]} 
        x={value["State"]["x"]} 
        y={value["State"]["y"]} 
        text={value["Place"]} 
        size={ScreenSize} 
        id={value["id"]}
        imageScale={imageScale}
      /> )}
    </div>
  );
}