import { a, useSpring } from "@react-spring/three";
import { useRef, useState } from "react";
import { useFrame } from "react-three-fiber";

import Flower from "../models/about/Flower";

export function NotedFlower({openRef, data, pos}) {

    const mesh = useRef(null);

    const [expand, setExpand] = useState(false);

    const props = useSpring({
        scale: expand ? [1.5, 1.5, 1.5] : [1, 1, 1],
        position: expand ? [0, -0.5, 0] : [pos[0], pos[1], pos[2]]
    });

    useFrame(
        () => {
            if (expand)
            {
                mesh.current.rotation.y += 0.01;
            }
        }
    )

    const clicked = () => {
        if (data["note"] === "NONE")
        {
            return;
        }

        if (!expand)
        {
            openRef(
                <p>
                    {data["note"]}
                </p>
            );
        }
        else 
        {
            openRef();
        }

        setExpand(!expand)
    }

    return (
        <a.group onClick={clicked} ref={mesh} scale={props.scale} position={props.position}>
            <Flower color={data["metadata"]["color"]}/>
        </a.group>
    );
}