/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { a, useSpring } from "@react-spring/three";
import { Suspense, useRef, useState } from "react";

import { useFrame } from "react-three-fiber";

import { MathUtils } from "three";

import Boba from "../models/about/Boba";
import Code from "../models/about/Code";
import Computer from "../models/about/Computer";
import FloppyDisk from "../models/about/FloppyDisk";
import Flower from "../models/about/Flower";
import Orca from "../models/about/Orca";
import Paddle from "../models/about/Paddle";
import Ram from "../models/about/Ram";
import RubixCube from "../models/about/RubixCube";
import Subway from "../models/about/Subway";
import Fire from "../models/about/Fire";
import Gavel from "../models/about/Gavel";
import Wand from "../models/about/Wand";

export default function Model({ callback }) {

  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState(0);

  const props = useSpring({
    scale: expand ? [1.4, 1.4, 1.4] : [1, 1, 1],
  });

  useFrame(
    () => {
      if (!shiftYRef || !callback) {
        return;
      }

      if (!shiftYRef.current) {
        return;
      }

      shiftYRef.current.rotation.y += 0.01;

      callback(shiftYRef, onExpand);
    }
  );

  const [currentIndex, setCurrentIndex] = useState(1);
  const onExpand = (oldPos, newPos, div) => {

    let pendingIndex = Math.floor(newPos * 0.01 / div);

    setCurrentIndex(newPos < oldPos ? pendingIndex : pendingIndex);
    // console.log(pendingIndex + " due to " + oldPos + " -> " + newPos + " dbug " + newPos * 0.01 / div)

    // shiftYRef.current.position.y = (spacer * 2) * (-pendingIndex);

    for (var i = 0; i < peopleRef.length; i++) {
      peopleRef[i].current.visible = i === pendingIndex;
    }
  }

  const shiftYRef = useRef();

  const peopleRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]

  const spacer = 0;

  function generateSpot() {

    const [x, y, z] = [
      MathUtils.randFloatSpread(250),
      MathUtils.randFloatSpread(250),
      MathUtils.randFloatSpread(250),
    ]

    const scale = MathUtils.randFloatSpread(10)

    return (
      <a.group position={[x, y, z]} scale={[scale, scale, scale]}>
        <mesh>
          <sphereBufferGeometry />
          <meshStandardMaterial attach="material" color="#F1F4FF" />
        </mesh>
      </a.group>
    );
  }

  let A = Array(75);
  // for (var i = 0; i < A.length; ++i)
  // {
  //   A[i] = generateSpot();
  // }

  return (
    <Suspense>

      <a.group>
        {A}
      </a.group>

      <a.group ref={shiftYRef}>
        <a.group ref={peopleRef[0]} position={[0, 0, 0]} scale={[0.1, 0.1, 0.1]}>
          <Subway position={[0, -3, 0]} scale={[2, 2, 2]} />
          <FloppyDisk position={[10, 1, -10]} />
          <Ram position={[-10, -3, 10]} rotation={[0, Math.PI/2 , Math.PI/2]} scale={[1.5, 1.5, 1.5]}/>
        </a.group>
        <a.group ref={peopleRef[1]} position={[0, spacer, 0]} scale={[0.1, 0.1, 0.1]} visible={false}>
          <Code position={[0, -4, 0]} scale={[5, 5, 5]} />
          <Code position={[0, -4, 10]} scale={[2, 2, 2]} />
          <Code position={[0, -4, -10]} scale={[2, 2, 2]} />
        </a.group>
        <a.group ref={peopleRef[2]} position={[0, spacer * 2, 0]} scale={[0.1, 0.1, 0.1]} visible={false}>
          <Fire position={[0, 2, 0]} scale={[5, 5, 5]} />
          <Fire position={[0, -2, 10]} scale={[2, 2, 2]} />
          <Fire position={[0, -2, -10]} scale={[2, 2, 2]} />
        </a.group>
        <a.group ref={peopleRef[3]} position={[0, spacer * 3, 0]} scale={[0.1, 0.1, 0.1]} visible={false}>
          <Paddle position={[0, 0, 0]} rotation={[0, 0, 0]}/>
          <Boba position={[7, 0, -5]} />
          <Flower position={[3, -1, -2]} scale={[2, 2, 2]} rotation={[0, 0, 30]}/>
        </a.group>
        <a.group ref={peopleRef[4]} position={[0, spacer * 4, 0]} scale={[0.1, 0.1, 0.1]} visible={false}>
          <Orca position={[0, 0, 0]} scale={[3, 3, 3]} rotation={[0, Math.PI*3/2, 0]}/>
          <Computer position={[10, 0, 0]} scale={[1.5, 1.5, 1.5]} rotation={[0, Math.PI * 4/5 + Math.PI, 0]}/>
          <RubixCube position={[-5, 0, 0]} />
        </a.group>
        <a.group ref={peopleRef[5]} position={[0, spacer * 5, 0]} scale={[0.1, 0.1, 0.1]} visible={false}>
          <Gavel position={[0, 0, 0]} scale={[3, 3, 3]} rotation={[0, Math.PI*3/2, 0]}/>
          <Wand position={[10, -3, 0]} scale={[3, 3, 3]} rotation={[0, Math.PI*3/2 - 0.5, Math.PI*3/2]}/>
          <Fire position={[5, -0.5, 9]} scale={[2, 2, 2]} rotation={[0, Math.PI/4, 0]}/>
        </a.group>
      </a.group>
    </Suspense>
  );
}
