import React, { useState, useEffect, useRef } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn, MDBCardLink, MDBCardFooter, MDBLazyLoading } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBFooter } from 'mdb-react-ui-kit';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdownLink, MDBCardGroup } from 'mdb-react-ui-kit';

import { useWindowDimensions } from 'react-native';
import { BigMap } from "../components/BigMap"
import { TypeWriter } from "../components/TypeWriter"

import {Parallax} from "react-parallax";

import { AnimationNavBar } from '../components/NavBar';
import Footer from '../components/Footer';
import { getShootingsData } from '../components/FirebaseManager';

function MakeCard(background, icon, title, description, link) {
    return (
        <MDBCard style={{ width: '225px', height: '300px', background: background }} position="center" align="center" className='h-100'>
            <div class="mt-4">
                <img src={icon} alt="card image" width="50px" height="50px"/>
            </div>
            <MDBCardBody class="mt-3">
                <MDBCardTitle style={{ fontSize: "20px" }}><b>  {link != null ? <MDBCardLink href={link} target="_blank" style={{ color: "#000" }}> {title} </MDBCardLink> : <p style={{color: "#000"}}>{title}</p>}</b></MDBCardTitle>
                <MDBCardText class="px-5">{description}</MDBCardText>
            </MDBCardBody>
        </MDBCard>
    );
}

function MakeShootingCard(location, date, link, deaths, injuries) {
    return (
        <MDBCard style={{ width: '235px', height: '160px', background: "#FFF6F6" }} position="center" align="center">
            <MDBCardBody class="my-0">
                <MDBContainer class="mx-0">
                    <MDBCol>
                        <MDBRow> 
                            <MDBCol style={{ background: "#FABEAB", width: "75px", height: "160px" }} class="rounded-end rounded-6 mx-0">
                                <MDBCardTitle style={{ fontSize: "12px", color: "#FABEAB" }} class="mb-3">a</MDBCardTitle>
                                <MDBCardTitle style={{ fontSize: "12px" }} class="mb-0">Deaths</MDBCardTitle>
                                <MDBCardTitle style={{ fontSize: "25px" }} class="mt-0"><b>{deaths >= 1000 ? Math.round(deaths/100)*100/1000 + "k": deaths}</b>
                                </MDBCardTitle>
                                <MDBCardTitle style={{ fontSize: "12px" }} class="mb-0">Injuries</MDBCardTitle>
                                <MDBCardTitle style={{ fontSize: "25px" }} class="mt-0">
                                    <b>{injuries >= 1000 ? Math.round(injuries/100)*100/1000 + "k": injuries}</b>
                                </MDBCardTitle>
                            </MDBCol>
                            <MDBCol style={{ width: "130px", height: "175px" }}>
                                <MDBCardTitle style={{ fontSize: "7px", color: "#FFF6F6" }} class="mb-3">a</MDBCardTitle>
                                <MDBCardLink href={"/shootings?id="+link} color="light" style={{ color: "#000", fontSize: "12px" }}>
                                    <MDBCardTitle class="my-0 mx-2" style={{ fontSize: "24px" }}><b>{location}</b></MDBCardTitle>
                                </MDBCardLink>
                                <MDBCardText class="mt-2" style={{ fontSize: "14px" }}>{date}</MDBCardText>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBContainer>
            </MDBCardBody>
        </MDBCard>
    );
}

function Home() {
    //lists
    const Missions = [
        [
            "#F6FBFF",
            "/images/missions/StopGlorif.png",
            "STOP SHOOTER GLORIFICATION",
            "A shooter should not be commended for their actions. Don't give them recognition. Don't give them a motive."
        ],
        [
            "#FAF6FF",
            "/images/missions/Humanize.png",
            "HUMANIZE NUMBERS",
            "Victims aren't just numbers. They aren't just statistics. Each and every victim had a personality. A story. A dream. So let them be heard. Write their story for the world to see."
        ],
        [
            "#F6FFFB",
            "/images/missions/PaintBig.png",
            "PAINT THE BIG PICTURE",
            "See the extent of America's gun violence epidemic through our map tracker database. Update it with any shootings that might have been missed."
        ],
        [
            "#FFFEF6",
            "/images/missions/Mural.png",
            "IN MEMORIUM",
            "Remember the lives of the people who lost their lives to shootings. Pay your respects and add monuments of their life on a personal memorium page."
        ],
        [
            "#FFF6F6",
            "/images/missions/Actions.png",
            "ACTIONS NEEDED",
            "Support any nationwide legislation that addresses gun safety, such as license registration for gun ownership and risk protection orders."
        ],
        [
            "#F6FFFE",
            "/images/missions/World.png",
            "NEVER AGAIN",
            "Let's be open in taking advice from other countries and our own political parties; only together can we ensure shootings never happen again. "
        ]
    ]

    const Support = [
        [
            "#F6FBFF",
            "/images/resources/support/Call.png",
            "24/7 Crisis Call Line",
            "Call +1 (908) 273-8255 to talk with a counslor over the phone.",
            "https://suicidepreventionlifeline.org"
        ],
        [
            "#F6FBFF",
            "/images/resources/support/Message.png",
            "24/7 Crisis Text Line",
            "Text “HOME” to 741-741 (In USA) to connect with a crisis counslor via SMS or WhatsApp.",
            "https://www.crisistextline.org"
        ],
        [
            "#F6FBFF",
            "/images/resources/support/NAMI.png",
            "NAMI Helpline",
            "Avaliable 10am-10pm ET \n\n Email at helpline@nami.org or call at +1 (800) 950-6264 or chat online.",
            "https://www.nami.org/help"
        ]
    ]

    const Prevention = [
        [
            "#F6FFFB",
            "/images/resources/support/Law.png",
            "Stricter Gun Laws",
            "Voting for stricter gun laws can thwart the process for those with malicious intent to obtain firearms. Write a letter to your senator. (template below)",
            "https://www.ncsl.org/portals/1/Documents/SampleLetterToYourSenator.pdf"
        ],
        [
            "#F6FFFB",
            "/images/resources/support/Warning.png",
            "Know The Signs",
            "Know the signs of gun violence before it happens. Don't hesitate to report them as your action can save lives.",
            "https://www.sandyhookpromise.org/blog/teacher-resources/know-the-signs-of-gun-violence/"
        ],
        [
            "#F6FFFB",
            "/images/resources/support/Aware.png",
            "Awareness",
            "Be aware of your surroundings, especially in areas of low security; take note of potential exits and cover. Knowing some basic self-defense can be a viable last resort."
        ]
    ]

    const FinancialSupport = [
        [
            "#FFFEF6",
            "/images/resources/support/Everytown.png",
            "Everytown",
            "The largest gun violence prevention organization in America. Organizer of WearOrange.",
            "https://www.everytown.org/donate/"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/SandyHook.png",
            "Sandy Hook Promise",
            "An organization working to prevent shootings from affecting schools, communities, and families after the Sandy Hook school shooting in 2012.",
            "https://takeaction.sandyhookpromise.org/a/foundation"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/Brady.png",
            "Brady United",
            "An organization bringing Americans together in the fight against gun violence by emphasizing action, such as educating the public and passing legislation.",
            "https://www.bradyunited.org/donate"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/MHA.png",
            "Mental Health America",
            "An organization that works to help survivors of shootings relieve their trauma.",
            "https://mhanational.org/donate-now"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/NAMIGold.png",
            "NAMI",
            "An organization working to understand motives behind gun violence and consequences whilst urging those in the government to promote the safety and mental health of Americans.",
            "https://donate.nami.org/give/197406/#!/donation/checkout?utm_source=globalNav&utm_medium=website&utm_campaign=DonationTracking&c_src=WEBDG"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/CrisisText.png",
            "Crisis Text Line",
            "A organization available 24/7 for when you need help the most.",
            "https://www.crisistextline.org/waystogive/"
        ],
        [
            "#FFFEF6",
            "/images/resources/support/SuicideHelpline.png",
            "Suicide Life Line",
            "An organization that rovides 24/7 service if you are contemplating suicide or in need of someone to talk to.",
            "https://suicidepreventionlifeline.org/donate/"
        ]
    ]

    const VolunteerService = [
        [
            "#FFF6F6",
            "/images/resources/support/GSV.png",
            "Gun Sense Voter",
            "Help advocate for public safety measures. Hold school walkouts and help peers register to vote. Join either the Moms Demand Action or the Students Demand Action groups, and help us make the country a safer place"
        ],
        [
            "#FFF6F6",
            "/images/resources/support/SHP_Red.png",
            "Sandy Hook Promise",
            "Promise leaders commit to atleast 3 volunteer projects a year, either helping in building awareness, promotion, advocacy, or fundraising. Resources will be provided after registration.",
            "https://www.sandyhookpromise.org/take-action/volunteer/details/"
        ],
        [
            "#FFF6F6",
            "/images/resources/support/CSGV.png",
            "CSGV",
            "Join the Coalition to Stop Gun Violence, which works to pass laws preventing all forms of gun violence. Help lobby and help fight for a safer country",
            "https://www.csgv.org/about-us/",
        ]
    ]

    const Protests = [
        [
            "#F6FBFF",
            "/images/resources/support/MFOL.png",
            "March For Our Lives",
            "An organization created by survivors of gun violence, holding frequent marches and protests against gun violence.",
            "https://marchforourlives.com/actions/"
        ],
        [
            "#F6FBFF",
            "/images/resources/support/WearOrange.png",
            "Wear Orange",
            "An organization committed to end gun violence in the near future. The organization encourages wearing orange at their protests.",
            "https://act.everytown.org/event/wear-orange-2022/search/"
        ],
        [
            "#F6FBFF",
            "/images/resources/support/MDA.png",
            "Moms Demand Action",
            "Moms Demand Action is a grassroots movement of Americans fighting for public safety measures that can protect people from gun violence.",
            "https://momsdemandaction.org/events/"
        ]
    ]

    //database
    const [shootingsData, setShootingsData] = useState([]);

    //for scrolling
    const missionsRef = useRef(null);

    //get database stuff w/ error catch
    useEffect(() => {
        getShootingsData(setShootingsData, "", true);
    }, [])
    
    //dropbox selector
    const [timeRangeSelected, setTimeRangeSelected] = useState("All Data");

    //get database contents based on selected filter
    const getFilteredShootingsData = () => {

        let filtered = [];
        const currentDate = new Date(new Date().toJSON().slice(0, 10).replace(/-/g,'/'));
        const showAfter = (timeRangeSelected !== "All Data" ? new Date(new Date().setDate(currentDate.getDate() - (timeRangeSelected === "Last 7 Days" ? 7 : (timeRangeSelected === "Last 31 Days" ? 31 : 365)))): new Date(new Date().setTime(0))).getTime(); //ticks

        for (const data of shootingsData) {

            if (data["id"].startsWith("_"))
            {
                continue;
            }

            const eventDate = new Date(data["Date"]).getTime();

            if (eventDate > showAfter){
                filtered.push(data);
            }
        }

        return filtered;
    }

    return (
        <>
            <AnimationNavBar />
            <MDBContainer class="align-items-center justify-content-center">
                <div class="bold-image" style={{backgroundImage: "url('./images/misc/TopImage.png')"}}>
                    {TypeWriter(
                        [
                            "WE ARE MORE THAN FIGURES\n WE ARE STUDENTS",
                            "WE ARE MORE THAN FIGURES\n WE ARE TEACHERS",
                            "WE ARE MORE THAN FIGURES\n WE ARE PARENTS",
                            "WE ARE MORE THAN FIGURES\n WE ARE HUMAN"
                        ],
                        true,
                        true,
                        () => {
                            if (window.pageYOffset != 0) return;
                            missionsRef.current.scrollIntoView()
                        },
                        50,
                        1000
                    )}
                </div>

                <Parallax bgImage="/images/missions/Bubbles.png" strength={300} className="pb-0 mb-0">
                    <MDBContainer>
                        <MDBRow ref={missionsRef} class="text-center padt-app row-no-gutters">
                            <MDBCard shadow='0' border='' background='transparent'>
                                <p id="our-mission" class="padb-app big-heading" style={{ color: "#FABEAB" }}>Our Mission</p>
                            </MDBCard>
                        </MDBRow>
                        <MDBRow className='row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                            {Missions.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3])}</MDBCol>)}
                        </MDBRow>
                    </MDBContainer>
                </Parallax>

                {/* <Parallax bgImage="/images/map/MapBG.png" strength={10}> */}
                {/*"#F1F4FF"*/}
                <div style={{backgroundColor: "#F1F4FF"}} className="pt-0 mt-0">
                    <MDBContainer>
                        <MDBRow class="text-center padt-5">
                            <p id="recent-shootings" class="mt-5 mb-3 big-heading mt-5 pt-5" style={{ color: "#FABEAB" }}>Recent Shootings Map</p>
                        </MDBRow>
                        <MDBRow class="">
                            <BigMap Places={getFilteredShootingsData()} ScreenSize={useWindowDimensions()}/>
                        </MDBRow>
                        <MDBRow class="text-center mb-4">
                            <MDBDropdown group className='shadow-0'>
                                <MDBDropdownToggle 
                                    size='sm' 
                                    color="light" 
                                    style={{ color: "#FABEAB", background: "#FFF6F6" }}
                                >{timeRangeSelected}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {setTimeRangeSelected("Last 7 Days")}} color="light" style={{ color: "#000" }}>Last 7 Days</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {setTimeRangeSelected("Last 31 Days")}} color="light" style={{ color: "#000" }}>Last 31 Days</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {setTimeRangeSelected("Last 365 Days")}} color="light" style={{ color: "#000" }}>Last 365 Days</MDBDropdownLink>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => {setTimeRangeSelected("All Data")}} color="light" style={{ color: "#000" }}>All Data</MDBDropdownLink>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBRow>
                        <MDBRow className='d-flex flex-row flex-nowrap overflow-auto map-cards-container' style={{ height: "200px"}}>
                            {getFilteredShootingsData().map(value => <MDBCol key={value["id"]} class="d-flex justify-content-center" style={{ width: "275px" }}>{MakeShootingCard(value["Place"], value["Date"], value["id"], value["Deaths"], value["Injuries"])}</MDBCol>)}
                        </MDBRow>
                    </MDBContainer>
                </div>
                {/* </Parallax> */}

                <Parallax class="overflow-hidden" bgImage="/images/resources/support/Background.png" strength={1000}>
                    <MDBCard shadow='0' border='' background='transparent'>
                        <MDBContainer>
                            <MDBRow class="text-center pady-app">
                                <p id="resources" class="big-heading" style={{ color: "#FABEAB" }}>Resources</p>
                                <p class="mt-0 sub-heading" style={{ color: "#FABEAB" }}>Crisis Support</p>
                            </MDBRow>
                            <MDBRow className='row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                                {Support.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3], value[4])}</MDBCol>)}
                            </MDBRow>
                            <MDBRow class="text-center pady-app">
                                <p class="pady-app sub-heading" style={{ color: "#FABEAB" }}>Preventions</p>
                            </MDBRow>
                            <MDBRow className='row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                                {Prevention.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3], value[4])}</MDBCol>)}
                            </MDBRow>
                        </MDBContainer>

                        <MDBContainer>
                            <MDBRow class="text-center pady-app">
                                <p id="how-can-i-help" class="big-heading" style={{ color: "#FABEAB" }}>How Can I Help?</p>
                                <p class="mt-0 sub-heading" style={{ color: "#FABEAB" }}>Financial Support</p>
                            </MDBRow>
                            <MDBRow className='row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                                {FinancialSupport.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3], value[4])}</MDBCol>)}
                            </MDBRow>
                            <MDBRow class="text-center pady-app">
                                <p class="mt-0 sub-heading" style={{ color: "#FABEAB" }}>Volunteer Service</p>
                            </MDBRow>
                            <MDBRow className='row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                                {VolunteerService.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3], value[4])}</MDBCol>)}
                            </MDBRow>
                            <MDBRow class="text-center pady-app">
                                <p class="mt-0 sub-heading" style={{ color: "#FABEAB" }}>Join/Create Protests</p>
                            </MDBRow>
                            <MDBRow className='row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 row-no-gutters'>
                                {Protests.map(value => <MDBCol class="d-flex justify-content-center">{MakeCard(value[0], value[1], value[2], value[3], value[4])}</MDBCol>)}
                            </MDBRow>
                            <MDBRow className="padb-app">
                            </MDBRow>
                        </MDBContainer>
                    </MDBCard>
                </Parallax>
            </MDBContainer>
            <Footer />
        </>
    );
}

export default Home;