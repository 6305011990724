import { MDBBtn, MDBIcon, MDBTextArea } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

export default function Redirect ({redirect, onAccept, onDeny, from, to, doc}) {

    if (redirect) {
        from =  new URLSearchParams(location.search).get("from");
        to = decodeURIComponent(new URLSearchParams(location.search).get("to"));
        doc = decodeURIComponent(new URLSearchParams(location.search).get("doc"));
    }

    const text = require("../texts/" + doc).Text;
    const navigate = useNavigate();

    const redirTo = () => {

        if (onAccept != null)
        {
            onAccept();
        }

        if (to != null)
        {
            navigate(to);
        }
    } 

    const redirFrom = () => {

        if (onDeny != null)
        {
            onDeny();
        }

        navigate(from);
    }

    return (
        <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
            <h1 className="pt-5 pb-2" style={{textAlign: "center"}}>Gatekeep</h1>
            <MDBTextArea 
                readonly
                value={text}
                label="Terms & Conditions"
                style={{width: "100%", height: "60vh"}}
                aria-describedby="below1"
            />
            <div style={{width: "100%"}} className="pt-3">
                <center>
                    <MDBBtn onClick={redirTo} style={{backgroundColor: "rgb(156, 255, 178)", color: "#000"}} className="me-3 mb-3">
                        <MDBIcon icon="check" className="me-2"/>
                        Accept &amp; Continue
                    </MDBBtn>
                    <MDBBtn onClick={redirFrom} style={{backgroundColor: "rgb(255, 127, 134)", color: "#000"}} className="me-3 mb-3">
                        <MDBIcon icon="times" className="me-2"/>
                        Back To Page
                    </MDBBtn>
                </center>
            </div>
        </div>
    );
}