import {MDBFooter } from 'mdb-react-ui-kit';

export default function Footer() {
    return (
        <MDBFooter className='rounded-top d-flex align-items-center justify-content-between' style={{ backgroundColor: '#FABEAB', height: "50px", width: "100%", textalign: "center" }}>
            <div className="ps-3 text-center ">
                <u><a href="/" style={{ color: "#000" }}>MoreThanJustFigures.org</a></u> written with &lt;3
            </div>
            <div className="text-center ">
                <u><a href="/about" style={{ color: "#000" }}>About Us</a></u>
            </div>
            <div className="pe-3 text-center">
                <u><a href="mailto:info@morethanjustfigures.org?subject=More Than Just Figures" style={{ color: "#000" }}>Contact Us</a></u>
            </div>
        </MDBFooter>
    );
}