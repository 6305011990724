/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/OMG.gltf");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Light"
          position={[4.08, 5.9, -1.01]}
          rotation={[1.89, 0.88, -2.05]}
        />
        <group
          name="Camera"
          position={[-10.19, 0.56, -0.87]}
          rotation={[1.56, -0.04, 1.37]}
        />
        <group name="OMGReference" rotation={[Math.PI / 2, 0, Math.PI]} />
        <mesh
          name="OMG"
          castShadow
          receiveShadow
          geometry={nodes.OMG.geometry}
          material={materials.OMGBlack}
          position={[0.36, -0.23, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/OMG.gltf");