import { MDBBtn, MDBCol, MDBContainer, MDBDatatable, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTextArea } from "mdb-react-ui-kit";
import { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import DatabaseEditor from "../components/DatabaseEditor";

import { getShootingsData, getTimelineData, getVictim, getVictimLink, PDINES_Shooting, PDINES_Victim, setTimeline, setVictimLink } from "../components/FirebaseManager";

import NotFound from "./NotFound";
import Redirect from "./Redirect";

import rerender from "../components/ForceUpdate"

export default function EditEvent() {
    const [trusted, setTrusted] = useState(localStorage.getItem("trusted"));

    const ID = new URLSearchParams(location.search).get("id");
    const editParameter = new URLSearchParams(location.search).get("edit");

    if (["timeline", "victimLink", "victimData", "shooting", "victimList"].indexOf(editParameter) == -1) {
        return <NotFound />;
    }

    const [shootings, setShootings] = useState([]);
    const [data, setData] = useState(null);
    const [victims, setVictims] = useState(null);

    useEffect(() => {

        if (editParameter != "victimData" && ID.replace(" ", "") != "") {
            getShootingsData(setShootings, ID);
        }

        switch (editParameter) {
            case "timeline":
                getTimelineData(setData, ID, true, true);
                break;
            case "victimLink":
                getVictimLink(setData, ID, false, true);
                getVictim(setVictims, "", true, true);
                break;
            case "victimData":
                if (ID.replace(" ", "") != "")
                {
                    getVictim(setVictims, ID, false, true);
                }
                else {
                    setVictims([]);
                }
                break;
            case "shooting":
                if (ID.replace(" ", "") == "") {//set none, no other set bc alr set above
                    setShootings([]);
                }
                break;
            case "victimList":
                getVictim(setData, "", true, true);
                break;
            }
        }, []);

    const pushToFirebase = (pushing, setPushing, rows) => {
        if (pushing) {
            return;
        }

        setPushing(true);

        let payload = {}

        switch (editParameter) {
            case "timeline":
                for (const value of rows) {
                    payload[value["Timestamp"]] = {
                        Title: value.Title,
                        Description: value.Description,
                        Icon: value.Icon
                    };
                }
                setTimeline(payload, ID);
                break;
            case "victimLink":
                payload = [];
                for (const value of rows) {
                    payload.push(value["VictimID"].split(" (")[0]);
                }
                setVictimLink(payload, ID);
                break;
            case "victimData":
                if (rows.length == 1)
                {
                    payload = {
                        Bio: rows[0]["Biography"],
                        Name: rows[0]["Name"],
                        Image: rows[0]["Image"],
                        Site: rows[0]["Site"],
                    };
                }
                PDINES_Victim(payload, ID);
                break;
            case "shooting":
                if (rows.length == 1)
                {
                    payload = {
                        Date: rows[0]["Date"].replace(" ", ""),
                        Place: rows[0]["Place"],
                        Deaths: Number(rows[0]["Deaths"]),
                        Injuries: Number(rows[0]["Injuries"]),
                        State: {
                            x: Number(rows[0]["X"]),
                            y: Number(rows[0]["Y"])
                        }
                    };
                }
                PDINES_Shooting(payload, ID);
                break;
        }
    }

    const validateData = (setDBReady, setErrorText, rows) => {

        const oldRows = rows;

        const newRows = [];
        const Errors = []

        switch (editParameter) {
            case "timeline":
                for (var index = 0; index < oldRows.length; index++) {
                    const element = oldRows[index];
                    const time = element["Timestamp"].split(" ");
                    
                    const humanIndex = index + 1;

                    if (!(time[0].includes("-") && time[1].includes(":"))) {
                        Errors.push("Row " + humanIndex + ": Timestamp is not in the correct format. Example timestamp: '5-3-2022 12:39 EST' for May 3rd, 2022 at 12:39pm EST.");
                        break;
                    }

                    const [month, day, year] = time[0].split("-");
                    const [hours, mins] = time[1].split(":");

                    let cont = false;

                    if (time.length != 3 || isNaN(Date.parse(year + "-" + (month.length == 1 ? "0" : "") + month + "-" + (day.length == 1 ? "0" : "") + day + "T" + (hours.length == 1 ? "0" : "") + hours + ":" + (mins.length == 1 ? "0" : "") + mins))) {
                        cont = true;
                        Errors.push("Row " + humanIndex + ": Timestamp is not in the correct format. Example timestamp: '5-3-2022 12:39 EST' for May 3rd, 2022 at 12:39pm EST.");
                    }
                    if (element["Timestamp"].replace(" ", "") === "") {
                        cont = true;
                        Errors.push("Row " + humanIndex + ": No timestamp found");
                    }
                    if (element["Title"].replace(" ", "") === "") {
                        cont = true;
                        Errors.push("Row " + humanIndex + ": No title found.");
                    }
                    if ((oldRows.map((value, i) => value["Timestamp"] === element["Timestamp"] && index != i)).indexOf(true) != -1) {
                        cont = true;
                        Errors.push("Row " + humanIndex + ": Duplicate timestamp found");
                    }

                    if (cont) {
                        continue;
                    }

                    newRows.push(element);
                }
                break;
            case "victimLink":

                let ridAlias = []

                for (var index = 0; index < oldRows.length; index++) {
                    ridAlias.push({
                        VictimID: oldRows[index]["VictimID"].split(" (")[0]
                    });
                }

                console.log(ridAlias)

                for (var index = 0; index < ridAlias.length; index++) {
                    const element = ridAlias[index];
                    if (ridAlias.map((e, i) => element["VictimID"] == e["VictimID"] && index != i).indexOf(true) != -1) {
                        Errors.push("Row " + index + ": Duplicate victim ID found");
                        continue;
                    }
                }
                break;
            case "victimData":

                if (oldRows.length == 0)
                {
                    break;
                }

                if (oldRows.length > 1)
                {
                    Errors.push("Only one row is allowed");
                }
                if (oldRows.length == 0)
                {
                    Errors.push("No entries found.");
                    break;
                }
                if (oldRows[0]["Name"].replace(" ", "") === "") {
                    Errors.push("Row 1: No name found.");
                }
                // if (oldRows[0]["Biography"].replace(" ", "") === "") {
                break;
            case "shooting":

                if (oldRows.length == 0)
                {
                    break;
                }

                if (oldRows.length > 1)
                {
                    Errors.push("Row 1: Only one row is allowed");
                }

                const Event = oldRows[0];
                
                if (Event["Date"].replace(" ", "") == "" || !(Event["Date"].includes("/") || Event["Date"].includes("-")) ) {
                    Errors.push("Row 1: Date is not in the correct format. Example date: '5/3/2022' for May 3rd, 2022.");
                    break;
                }
                oldRows[0]["Date"] = oldRows[0]["Date"].replace("-", "/");

                const [month, day, year] = Event["Date"].replace("-", "").split("/");

                if (isNaN(Date.parse(year + "-" + (month.length == 1 ? "0" : "") + month + "-" + (day.length == 1 ? "0" : "") + day))) {
                    Errors.push("Row 1: Date is not in the correct format. Example date: '5/3/2022' for May 3rd, 2022.");
                    break;
                }

                if (Event["Place"].replace(" ", "") === "")
                {
                    Errors.push("Row 1: No Place found.");
                }else if (!Event["Place"].includes(","))
                {
                    Errors.push("Row 1: Use comma to seperate City, State Abbreviation eg: Phoenix, AZ")
                }else if (Event["Place"].length > 25)
                {
                    Errors.push("Row 1: Too long, 25 char max, abbreviate city please.")
                }

                if (Event["Deaths"].replace(" ", "") === "" || isNaN(Event["Deaths"]))
                {
                    Errors.push("Row 1: Deaths is not a number.");
                }

                if (Event["Injuries"].replace(" ", "") === "" || isNaN(Event["Injuries"]))
                {
                    Errors.push("Row 1: Injuries is not a number.");
                }

                if (Event["X"].replace(" ", "") === "" || isNaN(Event["X"]))
                {
                    Errors.push("Row 1: Latitude is not a number.");
                }

                if (Event["Y"].replace(" ", "") === "" || isNaN(Event["Y"]))
                {
                    Errors.push("Row 1: Longitude is not a number.");
                }
                break;
        }

        let allowEmptyOrFilled = ["shooting"].indexOf(editParameter) == -1 || oldRows.length == 1;

        if (!allowEmptyOrFilled)
        {
            Errors.push("Payload can not be empty.")
        }

        setDBReady(Errors.length == 0 && allowEmptyOrFilled);
        setErrorText(Errors);
    }

    return (
        <>
            {
                trusted != null ? 
                (
                    <div>
                        <div className="ps-5 pe-5 pt-5">
                            {editParameter != "victimList" ? <h1>{shootings == null || shootings["Place"] == null ? <h1>Modifying {editParameter}</h1> : <h1>Modifying {editParameter} of {shootings["Place"]}</h1>}</h1> : <h1>{editParameter}</h1>}
            
                            {editParameter == "timeline" && data != null && <><DatabaseEditor
                                inCols={
                                    [
                                        {
                                            width: 250,
                                            label: 'Timestamp (M-D-Y H:M TZ) in 24-hour time, TZ 3 letter code',
                                            field: 'Timestamp',
                                        },
                                        {
                                            width: 250,
                                            label: 'Title',
                                            field: 'Title',
                                        },
                                        {
                                            width: 250,
                                            label: 'Description (Optional)',
                                            field: 'Description',
                                        },
                                        {
                                            width: 250,
                                            sort: false,
                                            defaultValue: 'none',
                                            options: ['caution', 'ok', 'person', 'investigating', 'none'],
                                            inputType: 'select',
                                            label: 'Icon (Optional)',
                                            field: 'Icon',
                                        },
                                    ]
                                }
                                inRows={
                                    data.map(value => {
                                        return {
                                            Timestamp: value["id"],
                                            Title: value["Title"],
                                            Description: value["Description"],
                                            Icon: value["Icon"]
                                        }
                                    })
                                }
                                link={"shootings?id=" + ID}
                                id={ID}
                                
                                validateData={validateData}
                                pushToFirebase={pushToFirebase}
                            />
                            <b>Notes:</b>
                            <br />
                            You can push with empty data but it will empty the section.
                            </>
                            }
                            {editParameter == "victimLink" && data != null && victims != null && <><DatabaseEditor
                                inCols={
                                    [
                                        {
                                            width: 250,
                                            label: 'Victim IDs (VIDs can be found in the URL header of their profile page)',
                                            field: 'VictimID',
                                            inputType: 'select',
                                            options: victims.map(value => value["id"] + " (" + value["Name"] + ")")
                                        },
                                    ]
                                }
                                inRows={
                                    data.map(value => {
                                        return {
                                            VictimID: value,
                                        }
                                    })
                                }
                                link={"shootings?id=" + ID}
            
                                validateData={validateData}
                                pushToFirebase={pushToFirebase}
                            />
                            <b>Notes:</b>
                            <br/>
                            You can only add profiles already in the database. If one isn't made yet, you can <a href="/editEvent?id=&edit=victimData" target="_blank">create a victim profile or edit an existing one.</a>.
                            <br/>
                            You can push with empty data but it will empty the section.
                            </>
                            }
                            {editParameter == "victimData" && victims != null && ((ID.replace(" ", "") == "" && victims.length == 0) || victims.length != 0) && <>
                            {ID.replace(" ", "") == "" && <MDBContainer style={{backgroundColor: "#efefef"}} className="pb-3 rounded-5">
                                <MDBIcon icon="info" size="lg" className="me-3 pt-3"/>
                                <b>Edit by ID:</b>
                                <br />
                                If you are here to edit an already existing victim id, please list it in the box and click load. Otherwise, continue to the below.
                                <br />
                                <MDBRow>
                                    <MDBCol>
                                    <MDBInput
                                        className="refresh-id"
                                        placeholder={"ID"}
                                    />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBBtn 
                                        onClick = {() => {
                                            const query = document.getElementsByClassName("refresh-id")[0].value;
                                            if (query === "")
                                            {
                                                return;
                                            }
                                            console.log(window.location.pathname.replace("id=", "id=" + query))
                                            open(window.location.pathname + "?id=" + query + "&edit=victimData", "_self");
                                        }}
                                        // href={window.location.pathname + "?id=" + document.getElementsByClassName("refresh-id")[0].value + "&edit=victimData"}
                                        style={{backgroundColor: "rgb(156, 255, 178)", color: "black"}}>
                                            Load
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        Don't know the ID, but know the name? <a href="/editEvent?id=&edit=victimList">Find ID</a>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>}
                            <br />
                            <br />
                            <DatabaseEditor 
                                inCols={
                                    [
                                        {
                                            width: 250,
                                            label: 'Full Name',
                                            field: 'Name'
                                        },
                                        {
                                            width: 250,
                                            label: "Short Description (Optional)",
                                            field: "Biography"
                                        },
                                        {
                                            width: 250,
                                            label: "Image Upload (Optional)",
                                            field: "Image"
                                        },
                                        {
                                            width: 250,
                                            label: "Link to personal site (Optional)",
                                            field: "Site"
                                        }
                                    ]
                                }
            
                                inRows={ (victims.length == 0) ? 
                                    [] :
                                    [
                                        {
                                            Name: victims["Name"],
                                            Biography: (victims["Bio"] != null ? victims["Bio"] : ""),
                                            Image: (victims["Image"] != null ? victims["Image"] : ""),
                                            Site: (victims["Site"] != null ? victims["Site"] : ""),
                                        }
                                    ]
                                }
            
                                link={"/"}
                                validateData={validateData}
                                pushToFirebase={pushToFirebase}
                            />
                            <b>Notes:</b>
                            <br />
                            Image uploads should be 200x100px and uploaded to free hosting services such as <a href="https://imgur.com/" target="_blank">Imgur</a> with the <b>Image URL</b> pasted here.
                            <br />
                            You can create memorial sites for the victim using free online drag-and-drop websites like <a href="https://wix.com" target="_blank">Wix</a> and paste the <b>Web URL</b> here.
                            </>
                            }
                            {editParameter == "shooting" && shootings != null  && ((ID.replace(" ", "") == "" && shootings.length == 0) || shootings.length != 0) && <DatabaseEditor 
                                inCols={
                                    [
                                        {
                                            width: 250,
                                            label: 'Place (City, State Abbreviation)',
                                            field: 'Place'
                                        },
                                        {
                                            width: 250,
                                            label: 'Date (M/D/Y)',
                                            field: 'Date'
                                        },
                                        {
                                            width: 50,
                                            label: "Deaths",
                                            field: "Deaths"
                                        },
                                        {
                                            width: 50,
                                            label: "Injuries",
                                            field: "Injuries"
                                        },
                                        {
                                            width: 50,
                                            label: "Latitude (Decimal)",
                                            field: "X"
                                        },
                                        {
                                            width: 50,
                                            label: "Longitude (Decimal)",
                                            field: "Y"
                                        }
                                    ]
                                }
            
                                inRows={(shootings.length == 0) ? 
                                    [] :
                                    [
                                        {
                                            Place: shootings["Place"],
                                            Deaths: shootings["Deaths"],
                                            Injuries: shootings["Injuries"],
                                            Date: shootings["Date"],
                                            X: shootings["State"]["x"],
                                            Y: shootings["State"]["y"]
                                        }
                                    ]
                                }
            
                                link={"/"}
                                validateData={validateData}
                                pushToFirebase={pushToFirebase}
                            />}
                            {
                                editParameter == "victimList" && data != null && <MDBDatatable 
                                    search
                                    data={{
                                        columns: ["Name", "ID"],
                                        rows: data.map(value => [value["Name"], value["id"]])
                                    }}
                                />
                            }
                        </div>
                        {ID && <p>id: <code>{ID}</code></p>}
                        <br />
                        If you are not seeing the database editor but only the title or a white screen, you may have an invalid ID in your url header, clear it and reload, or go to the <a href="/">homepage</a>
                        <br />
                        <b>Again: Please do not break the database by trying to input values not specified.</b> 
                    </div>
                ) 
                : 
                (
                    <Redirect 
                        redirect={false} 
                        onAccept={() => {
                            localStorage.setItem("trusted", String(new Date()));
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000)
                        }} 
                        onDeny = {() => {localStorage.removeItem("trusted")}} 
                        doc={"DontBeAJerk.js"} 
                        from={"/"}
                    />
                )
            }
        </>
    );
}