/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Subway({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/subwayreachored_railed.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-8.15, 0, -0.59]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_1.geometry}
          material={materials.M_Wood_Oak}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_2.geometry}
          material={materials["M_Metal_Chrome.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_3.geometry}
          material={materials.M_Metal_Chrome}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_4.geometry}
          material={materials.M_Metal_Brushed_Nickel}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_5.geometry}
          material={materials.M_Metal_Burnished_Steel}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_6.geometry}
          material={materials.WorldGridMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_7.geometry}
          material={materials.M_LightStage_Skybox_Black}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_8.geometry}
          material={materials.M_Metal_Steel}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_9.geometry}
          material={materials.M_Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.M_Rail_10.geometry}
          material={materials.M_White}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/subwayreachored_railed.gltf");

