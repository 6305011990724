/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Computer({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/computer.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_MERGED_BP_Terminal_2_1.geometry}
          material={materials.M_Status}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_MERGED_BP_Terminal_2_2.geometry}
          material={materials.M_TerminalTopColor}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_MERGED_BP_Terminal_2_3.geometry}
          material={materials.M_EjectButton}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_MERGED_BP_Terminal_2_4.geometry}
          material={materials.Material}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/computer.gltf");
