import {
    MDBAnimatedNavbar, MDBCollapse, MDBContainer, MDBIcon, MDBDropdownToggle, MDBNavbar, MDBNavbarBrand, MDBNavbarItem,
    MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler, MDBDropdownLink, MDBDropdownItem, MDBDropdownMenu, MDBDropdown
} from "mdb-react-ui-kit";
import { useState } from "react";

export function NavBarContainer(textProps) {    

    const [showResponsiveDropdown, setShowResponsiveDropdown] = useState(false);
    const [showCreateDropdown, setShowCreateDropdown] = useState(false);

    return (
        <MDBContainer>
            <MDBNavbarBrand href={window.location.pathname != "/" ? "/" : "#"}>
                <MDBNavbarLink {...textProps}>MoreThanJustFigures.org</MDBNavbarLink>
            </MDBNavbarBrand>
            <MDBNavbarToggler
                type="button"
                data-target="#navbarColor02"
                aria-controls="navbarColor02"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setShowResponsiveDropdown(!showResponsiveDropdown)}
            >
                <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>
            <MDBCollapse show={showResponsiveDropdown} navbar>
                <MDBNavbarNav fullWidth={false} right className="mb-2 mb-lg-0">
                    <MDBNavbarItem>
                        <MDBNavbarLink {...textProps} href={window.location.pathname != "/" ? "/" : "#"}>Home</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                        <MDBNavbarLink {...textProps} href="/#recent-shootings">Recent Shootings</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                        <MDBNavbarLink {...textProps} href={window.location.pathname != "/about" ? "/about" : "#"}>About Us</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                    <MDBDropdown>
                        <MDBDropdownToggle tag="a" className='nav-link' onClick={() => setShowCreateDropdown(!showCreateDropdown)}>
                            <MDBIcon icon="plus" className="pe-2"/>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu style={{zIndex: "250"}} className="mt-3">
                            <MDBDropdownItem>
                                <MDBDropdownLink href={"editEvent?id=&edit=shooting"}>Report New Shooting</MDBDropdownLink>
                                {/* <MDBDropdownLink href={"/redirect?doc=DontBeAJerk&from=" + encodeURIComponent(window.location.href) + "&href=" + encodeURIComponent("editEvent?id=&edit=shooting")}>Report New Shooting</MDBDropdownLink> */}
                            </MDBDropdownItem>
                            <MDBDropdownItem>
                                <MDBDropdownLink href={"editEvent?id=&edit=victimData"}>Add Victim</MDBDropdownLink>
                                {/* <MDBDropdownLink href={"/redirect?doc=DontBeAJerk&from=" + encodeURIComponent(window.location.href) + "&href=" + encodeURIComponent("editEvent?id=&edit=victimData")}>Add Victim</MDBDropdownLink> */}
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                    </MDBNavbarItem>
                </MDBNavbarNav>
            </MDBCollapse>
        </MDBContainer>
    );
}

export function NavBar({color = "transparent"}) {
    return (
        <MDBNavbar expand="md" fixed="top" className="navbar" light style={{backgroundColor: color}}>
            <NavBarContainer style={{color: "#000"}}/>
        </MDBNavbar>
    );
}

export function AnimationNavBar(props) {
    return (
        <MDBAnimatedNavbar expand="md" fixed="top" className="navbar">
            <NavBarContainer />
        </MDBAnimatedNavbar>
    );
}