//backend
import { get, push, ref, set } from "firebase/database"
import DB from "../firebase-config"

const delay = ms => new Promise(res => setTimeout(res, ms));

async function getPosts (dataRef, setData, processListOfMap, bandwidthLimit = 5, emptyOnNull = true) {
    try {
        await get(dataRef).then(data => {
            var dataValue = data.val();

            // console.log(dataValue);

            if ((data == null || data.val() == null) && emptyOnNull)
            {
                setData([]);
                return;
            }

            if (!processListOfMap)
            {
                setData(dataValue); 
                return; //oops forgot early return
            }

            for (var id in dataValue) {
                dataValue[id]["id"] = id;
                //want to keep track of firebase id for reference later
            }

            const mapped = new Map(Object.entries(dataValue));
            const processedData = Array.from(mapped.values());

            setData(processedData);
        })
    }
    catch (error){
        console.log(error);

        if (bandwidthLimit <= 0)
        {
            return;
        }

        await delay(5000);
        await getPosts(dataRef, setData, processListOfMap, bandwidthLimit - 1, emptyOnNull);
    }
}

function getData(root, setData, id, processListOfMap, emptyOnNull = false)
{
    const dataRef = ref(DB, root + "/" + id);
    getPosts(dataRef, setData, processListOfMap, 5, emptyOnNull);
}

export function getShootingsData(setData, id = "", processListOfMap = false, emptyOnNull = false) {
    getData("Shootings", setData, id, processListOfMap, emptyOnNull);
}

export function getNotesData(setData, id = "", processListOfMap = false, emptyOnNull = false) 
{
    getData("Notes", setData, id, processListOfMap, emptyOnNull);
}

export function getTimelineData(setData, id="", processListOfMap = false, emptyOnNull = false) 
{
    getData("AdditionalData/Timeline", setData, id, processListOfMap, emptyOnNull);
}

export function getVictimLink(setData, id="", processListOfMap = false, emptyOnNull = false) 
{
    getData("AdditionalData/VictimLink", setData, id, processListOfMap, emptyOnNull);
}

export function getVictim(setData, id="", processListOfMap = false, emptyOnNull = false) 
{
    getData("Victims", setData, id, processListOfMap, emptyOnNull);
}

function pushData(payload, root)
{
    const dataRef = ref(DB, root + "/");
    push(dataRef, payload);
}

export function pushNote(payload, id)
{
    pushData(payload, "Notes/"+id)
}

function pushDataIfNoneElseSet(payload, root, id="")
{
    //precond id is valid in the list
    if (id != "")
    {
        setData(payload, root + "/" + id);
    }
    else
    {
        pushData(payload, root + "/" + id);
    }
}

export function PDINES_Victim(payload, id = "")
{
    pushDataIfNoneElseSet(payload, "Victims", id);
}

export function PDINES_Shooting(payload, id = "")
{
    pushDataIfNoneElseSet(payload, "Shootings", id);
}

function setData(payload, root)
{
    const dataRef = ref(DB, root + "/");
    set(dataRef, payload);
}

export function setTimeline(payload, id)
{
    setData(payload, "AdditionalData/Timeline/"+id)
}

export function setVictimLink(payload, id)
{
    setData(payload, "AdditionalData/VictimLink/"+id)
}