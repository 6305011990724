// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import {getDatabase} from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMCJXkVE8nZ2T5O9ehy1GCqr-RtcNS3tI",
  authDomain: "more-than-just-figures.firebaseapp.com",
  databaseURL: "https://more-than-just-figures-default-rtdb.firebaseio.com",
  projectId: "more-than-just-figures",
  storageBucket: "more-than-just-figures.appspot.com",
  messagingSenderId: "411242997077",
  appId: "1:411242997077:web:177e31d37ba80f93d7bbdc",
  measurementId: "G-3L9W9KP15S"
};

initializeApp(firebaseConfig);

const DB = getDatabase(getApp());

export default DB;