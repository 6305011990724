import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useState } from "react";
import { getVictim, getVictimLink } from "./FirebaseManager";

function Card({ value, victimDetailsList }) {

    const [openSite, setOpenSite] = useState(false);

    return (
        <MDBCard style={{ textAlign: "left" }} className="mb-4">
            {!openSite &&<MDBContainer>
                <MDBRow>
                    {victimDetailsList[value]["Image"] && <MDBCol size="4" className="ms-0 ps-0">
                        <img src={victimDetailsList[value]["Image"]} width="100px" height="200px" object-fit="cover" />
                    </MDBCol>}
                    <MDBCol>
                        <MDBCardTitle className="mt-4" style={{ textAlign: "center" }}>
                            {victimDetailsList[value]["Name"]}
                        </MDBCardTitle>
                        <MDBCardBody>
                            {victimDetailsList[value]["Bio"] && victimDetailsList[value]["Bio"]}
                        </MDBCardBody>
                        {victimDetailsList[value]["Site"] && <MDBCardFooter style={{ textAlign: "center" }}>
                            <a onClick={() => setOpenSite(true)} style={{color: "link"}} target="_blank">Visit Site</a>
                        </MDBCardFooter>}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>}
            {openSite && <MDBContainer>
                <MDBCardTitle>
                    You are about to visit <b>"{victimDetailsList[value]["Site"]}". Confirm?</b>
                </MDBCardTitle>
                <MDBCardBody>
                    <center>
                        <MDBBtn onClick={() => {window.open(victimDetailsList[value]["Site"], "_blank").focus(), setOpenSite(false)}} className="me-2" style={{color: "#000", backgroundColor: "rgb(156, 255, 178)"}}>
                            Yes
                        </MDBBtn>
                        <MDBBtn onClick={() => setOpenSite(false)} style={{color: "#000", backgroundColor: "rgb(255, 127, 134)"}}>
                            No
                        </MDBBtn>
                    </center>
                </MDBCardBody>
            </MDBContainer>}
        </MDBCard>
    );
}

export default function VictimPanel({ id }) {

    const [victimIDList, setVictimIDList] = useState([]);
    const [victimDetailsList, setVictimDetailsList] = useState([]);

    useEffect(() => {
        getVictimLink(setVictimIDList, id);
        getVictim(setVictimDetailsList);
    }, [])

    return (
        <>
            {victimIDList && victimIDList.map(value =>
                victimDetailsList[value] && <Card value={value} victimDetailsList={victimDetailsList}/>
            )}
        </>
    );
}