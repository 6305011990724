/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Boba({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/Boba.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[2, 1, 2]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_1.geometry}
          material={materials.Cup}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_2.geometry}
          material={materials.Boba}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_3.geometry}
          material={materials.Straw}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Boba.gltf");
