import { Suspense, useEffect, useRef, useState } from "react";

import { Canvas } from "react-three-fiber";

import { OrbitControls } from "@react-three/drei";

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCollapse, MDBContainer } from "mdb-react-ui-kit";
import Model from '../components/Model';

import { NavBar } from "../components/NavBar";
import { TypeWriter } from "../components/TypeWriter";

function About() {

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset - 200);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const perFrame = (ref, callback) => {
    const div = 10;
    const lmt = 3;
    let pendingPos = ((scrollPosition * 0.01)) % div;
    pendingPos = (pendingPos <= (div / 2) ? pendingPos : (div / 2) - (pendingPos - (div / 2)));
    pendingPos = (pendingPos < lmt ? pendingPos : lmt);

    ref.current.scale.z = ref.current.scale.x = ref.current.scale.y = pendingPos;

    if (Math.floor((lastScrollPosition * 0.01) / div) !== Math.floor((scrollPosition * 0.01) / div)) {
      callback(lastScrollPosition, scrollPosition, div);
    }

    setLastScrollPosition(scrollPosition);
  }

  const Bios = [
    [
      "Jason",
      "Founder & Full-stack Developer",
      "I decided to start this project after the tragedy in Uvalde, Texas. I had seen pictures of the in-person memorial and decided to create a replica of such online. I like trains, building computers, and cows."
    ],
    [
      "Larry",
      "Web designer & Front-end Developer",
      "I'm Larry. I'm a programmer, web designer (mostly front-end), and a gamer. I wanted to help those affected by gun violence, so I designed & coded this site."
    ],
    [
      "Agni",
      "Advisor Board",
      "Hi, I am Agni the Great. I am looking forward to working with and assisting the bright individuals at MoreThanJustFigures.org. Empathizing with those affected by gun violence is something we wish to help."
    ],
    [
      "Sushant",
      "Writer & Content Creator",
      "Hi, my name is Sushant and I live in Somerset, NJ. I'm interested in gun violence because I can't help to think: “What if it was me?” I enjoy reading, watching Netflix, drinking (taro) boba, eating ice cream and I'm on the national team for table tennis."
    ],
    [
      "Ruoyu",
      "3D-Artist",
      "Hey, I'm Ruoyu. I'm a game developer and programmer, though I've mainly created the 3D models for the site. The recent spike in gun violence is awful and something needs to be changed. I'm eager to take part in this project and spread awareness. "
    ],
    [
      "Mythri",
      "Writer & Public Relations",
      "When I learned about this project, still in early stages, I knew I had to help. Someone needs to act against the constant rising violence in this country. I work on content and marketing. I like building 3D-models, Harry Potter, and Law and Order.",
    ]
  ]

  const firstRef = useRef(null);
  const [description, setDescription] = useState("");

  //touch support disables side pan bc no scroll
  var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  let FormattedBios = Bios.map((Details, Index) => {
    return (<div id = {(Index == 0 ? "first" : "")} ref={(Index == 0 ? firstRef : null)} className={(Index == 0 ? "scroll-elements-text-start " : "") + (Index == Bios.length - 1 ? " " : "scroll-elements-text ") + "big-heading ps-4"} style={{ textAlign: "left" }}>
      <MDBCard background='transparent' style={{ width: "340px" }}>
        <MDBCardBody>
          {Details[0]}
          <MDBCardTitle>{Details[1]}</MDBCardTitle>
          <p style={{ fontSize: "16px", marginBottom: "0px", lineHeight: "25px" }} className="pt-0 pb-2">{Details[2]}</p>
        </MDBCardBody>
      </MDBCard>
    </div>);
  })

  return (
    <>
      <NavBar />
      <div style={{ width: "100%", height: "100%", position: "absolute", background: "url(./images/missions/Bubbles.png)" }}>
        <Canvas className={supportsTouch? "undraggable-image": "canvas-frame-about"} shadows camera={{ position: [-5, 2, 10], fov: 40 }} style={{ top: "0", left: "0", position: "fixed",  width: "100%", height: "100%" }}>
          <ambientLight intensity={1}></ambientLight>
          <directionalLight
            castShadow
            position={[0, 10, 0]}
            intensity={1.5}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            shadow-bias={-0.0005}
            shadow-camera-near={0.01}
            shadow-camera-far={40}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={-10}
            shadow-camera-bottom={10}
          />
          <pointLight position={[10, 0, 20]} intensity={1.5} />
          <pointLight position={[-10, 0, -20]} intensity={1.5} />

          <Suspense>
            <Model callback={perFrame} />
          </Suspense>
          {!supportsTouch && <OrbitControls enableRotate={true} enableZoom={false} enablePan={false} />}
        </Canvas>

        <div style={{position: "absolute", top: "90px", left: "10px"}}>
          <a href="/acknowlegements" style={{color: ""}}>
              View Acknowlegements
            </a>
            <br />
            <a href="/about#first">
              Meet Our Team Below
          </a>
        </div>

        <main style={{position: "relative"}}>
          <div className="text-over">
            <p style={{ fontSize: "100px" }}>
              {FormattedBios}
            </p>
          </div>
        </main>

        <MDBContainer style={{ textAlign: "center", color: "#FABEAB"}}>
          {
            TypeWriter(
              [
                "About Us",
              ],
              true,
              true,
              () => {
                setDescription("More Than Just Figures is an organization dedicated to helping those affected by gun violence by providing resources and a community-driven memorial.")
              },
              50,
              1000,
              "typewriter-about"
            )
          }
          <MDBContainer style={{maxWidth: "500px", alignContent: "center", display: "block" }}>
            <p style={{textAlign: "center"}}>{description}</p>
          </MDBContainer>
        </MDBContainer>
      </div>
    </>
  );
}

export default About;