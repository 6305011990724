const Text = `You are about to be given access to modify the database the site runs on. 
This great power, comes with great responsabilities, some of which listed below.
By clicking on the "Agree & Continue" button, you agree to abide by these terms.

1. Be serious, be courteous, be sensitive- it's people who are at stake, and please don't be a jerk.
2. Don't abuse the system in any way. For example: do not try to delete database entries that others wrote unless they falsely represent a situation.
3. Please do not break the database by trying to input values not specified. This is a passion project and there may not be all the input checks to prevent users from breaking the site/db. Please be courteous!
4. For your sake, do not leave sensitive information in the database.

Your ability to modify the database is a privilege granted, and can be revoked anytime.
For the sake of security, this message will appear again in 1 hour.

Thank you for your cooperation,
MoreThanJustFigures Team
`

exports.Text = Text;