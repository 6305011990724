const Text = `Thank you so much to the following:

Figma Iconify Plugin & Icons (Akar Icons mostly)

Landing page gun image - 
Credit:	Josiah S
Creative #:	1219883323
License type:	Royalty-free
Collection:	iStock
Location:	Japan
Release info:	No release required

Map image -
<a href="https://www.vecteezy.com/free-vector/america-map">America Map Vectors by Vecteezy</a>

Coded with <3 via React.js.
`

exports.Text = Text;