import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Home from "./pages/Home"
import About from "./pages/About"
import Shootings from "./pages/Shootings"
import NotFound from "./pages/NotFound"
import EditEvent from "./pages/EditEvent"
import Redirect from "./pages/Redirect"
import Ack from "./pages/Ack"

// import Trusted from "./components/AuthContext"

import React, { useContext, useState } from "react"

export default function App() {
  
  const hourAgo = ((new Date().getTime()) - 60*60000);
  const lastAccept = new Date(localStorage.getItem("trusted")).getTime();

  if (localStorage.getItem("trusted") === null || hourAgo > lastAccept)
  {
    localStorage.removeItem("trusted");
  }

  console.log(localStorage)

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/shootings" element={<Shootings />} />
          <Route path="/editEvent" element={<EditEvent/>}/>
          <Route path="/acknowlegements" element={<Ack />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}