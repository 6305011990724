/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Wand(props) {
  const { nodes, materials } = useGLTF("/models/Wand.gltf");
  return (
    <group {...props} dispose={null}>
      <group position={[0, -1.5, 0]} scale={[0.13, 1, 0.13]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_1.geometry}
          material={materials.Wand}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_2.geometry}
          material={materials.Handle}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Wand.gltf");
