
import { MDBBtn, MDBCol, MDBRow, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import Footer from '../components/Footer';

export default function NotFound() {
    return (
        <MDBContainer>
            <MDBIcon icon="question" size="10x" className="pt-5"/>
            <h1 style={{fontSize: "75px", paddingTop: "50px", paddingBottom: "20px"}} className="heading">Page Not Found</h1>
            <p>The page was not found in our server. Maybe try the following sites? </p> 
            <Footer />
        </MDBContainer>
    );
}