/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/";
import { MeshBasicMaterial } from "three";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/Flower.gltf");

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials.Stem}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder_1.geometry}
        material={props.color == null && materials.Petals} 
      >
        {props.color != null && <meshStandardMaterial attach="material" color={props.color} />}
      </mesh>
    </group>
  );
}

useGLTF.preload("/models/Flower.gltf");