import { MDBTextArea } from "mdb-react-ui-kit";
import Footer from "../components/Footer";
import { NavBar } from "../components/NavBar";

export default function Ack() {

    const text = require("../texts/AdditionalCredits").Text;

    return (
        <>
            <NavBar />
            <div style={{paddingLeft: "50px", paddingRight: "50px"}} className="pb-5">
                <h1 className="big-heading padt-app pb-4">Acknowlegements &amp; Additional Credits</h1>
                <MDBTextArea 
                    className="ps-4 pe-4"
                    readonly
                    value={text}
                    style={{width: "100%", height: "60vh"}}
                />
                <br />
                <center>
                    <a href="/about">Back to about</a>
                </center>
            </div>
        </>
    );
}