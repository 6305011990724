import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { getTimelineData } from "./FirebaseManager";

export default function Timelines({id})
{
    const [timelines, setTimelines] = useState([])

    useEffect(() => {
        getTimelineData(setTimelines, id, true);
    }, [])

    return (
        <>
            {timelines.map((values, index) =>
            <MDBContainer key={index}>
                <MDBRow style={{width: "340px", height: "90px"}}>
                    <MDBCol size="4">
                        <div style={{position: "relative"}}>
                            <div style={{position: "absolute", top: "70px", left: "-45px",textAlign: "center", width: "200px"}}>
                                <p>{values["id"].split(" ")[1]}</p>
                            </div>
                            {(index == 0 || timelines[index - 1]["id"].split(" ")[0] != values["id"].split(" ")[0]) && <div style={{position: "absolute", top: "-2px", left: "-80px",textAlign: "center", width: "200px"}}>
                                <u><p>{values["id"].split(" ")[0]}</p></u>
                            </div>}
                            {values["Icon"] !== "none" && <div style={{position: "absolute", top: "20px", left: "30px",textAlign: "left", width: "200px"}}>
                                <img className="undraggable-image" src={"./images/timeline/icons/" + values["Icon"] + ".png"}  alt="image"/>
                            </div>}
                            <img className="undraggable-image" src={"./images/timeline/TimelineTree"+ (index == timelines.length - 1 ? "End" : "") + ".png"} width="140px" height="90px"  alt="background"/>
                        </div>
                    </MDBCol>
                    <MDBCol size="8" className="pt-2 ps-5" style={{textAlign: "left", wordWrap: "break-word"}}>
                        <h3 className="no-padding mb-0">{values["Title"]}</h3>
                        <p className="no-padding mt-0" style={{fontSize: "10px"}}>{values["Description"]}</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            )}
        </>
    );
}