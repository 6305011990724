import {useState, useEffect, setState} from 'react';
import { MDBTableEditor } from "mdb-react-table-editor";
import { MDBBtn, MDBIcon, MDBSpinner, MDBTextArea } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import { setTimeline, setVictimLink } from './FirebaseManager';

export default function DatabaseEditor({inCols = [], inRows = [], link = "", validateData=null, pushToFirebase=null}){
    const [data, setData] = useState({
        columns: inCols,
        rows: inRows
    });

    const [DBReady, setDBReady] = useState(false);
    const [errorText, setErrorText] = useState([]);
    const [pushing, setPushing] = useState(false);

    const navigate = useNavigate();

    const pushAndRedir = () =>
    {
        pushToFirebase(pushing, setPushing, data.rows);
        navigate("../" + link);
    }

    const redirectToValidateData = () => {
        validateData(setDBReady, setErrorText, data.rows)
    }

    return (
        <div>
            <MDBTableEditor
                modal
                data={data}
                setData={(e) => {setData({...data, rows: e}), setDBReady(false)}}
            />
            <MDBBtn onClick={() => {!DBReady ? redirectToValidateData(): pushAndRedir()}} className="mb-3 me-3" style={{color: "#000", backgroundColor: !DBReady ? "rgb(155, 232, 255)":"rgb(156, 255, 178)"}}>
                {!pushing && <MDBIcon icon="check" className="pe-2"/>}
                {pushing && <MDBSpinner style={{width: "15px", height: "15px"}} className="me-2"/>}
                {!DBReady ? "Validate Data" : "Push To Firebase"}
            </MDBBtn>
            {!pushing &&
                <MDBBtn onClick={() => {navigate("../" + link)}} className="mb-3" style={{color: "#000", backgroundColor: "rgb(255, 127, 134)"}}>
                    <MDBIcon icon="times" className="pe-2"/>
                    Back To Page
                </MDBBtn>
            }
            {
                errorText.length > 0 &&
                <MDBTextArea label="Data Validation Errors" className="padb-app" contentEditable={false} style={{width: "100%", height: "300px"}} value={errorText.join("\n")}/>
            }
        </div>
    );
}